import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  FormFeedback,
  Label,
} from 'reactstrap';
const RewardRedemption = () => {
  return (
    <>
      <h4 class="fs-14 fw-medium mb-3 card-title">Set Redemption Points</h4>
      <Row>
        <Col md={6} xl={4} xxl={3} className="mb-3">
          <div className="p-3 pb-0 bg-light border rounded border-soft-dark">
            <Row className="mb-0">
              <Col md="12">
                <p className="mb-3 pb-2 border-bottom">
                  <strong>UAE</strong>
                </p>
              </Col>
              <Col md="12">
                <Label>Min. Points require to redeem</Label>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      value=""
                      placeholder="Enter point"
                      min={0}
                    />
                    <InputGroupText className="rounded-end">
                      Points
                    </InputGroupText>
                    <FormFeedback>ddsdsd</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="form-check form-switch form-switch-md form-switch-secondary mb-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="SwitchCheck2"
                    defaultChecked
                  />
                  <Label className="form-check-label " htmlFor="SwitchCheck2">
                    Set Max. Limits per transaction
                  </Label>
                </div>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      value=""
                      placeholder="Enter point"
                      min={0}
                    />
                    <InputGroupText className="rounded-end">
                      Points
                    </InputGroupText>
                    <FormFeedback>ddsdsd</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6} xl={4} xxl={3} className="mb-3">
          <div className="p-3 pb-0 bg-light border rounded border-soft-dark">
            <Row className="mb-0">
              <Col md="12">
                <p className="mb-3 pb-2 border-bottom">
                  <strong>OMAN</strong>
                </p>
              </Col>
              <Col md="12">
                <Label>Min. Points require to redeem </Label>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      value=""
                      placeholder="Enter point"
                      min={0}
                    />
                    <InputGroupText className="rounded-end">
                      Points
                    </InputGroupText>
                    <FormFeedback>ddsdsd</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="form-check form-switch form-switch-md form-switch-secondary mb-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="SwitchCheck2"
                    defaultChecked
                  />
                  <Label className="form-check-label " htmlFor="SwitchCheck2">
                    Set Max. Limits per transaction
                  </Label>
                </div>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      value=""
                      placeholder="Enter point"
                      min={0}
                    />
                    <InputGroupText className="rounded-end">
                      Points
                    </InputGroupText>
                    <FormFeedback>ddsdsd</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={6} xl={4} xxl={3} className="mb-3">
          <div className="p-3 pb-0 bg-light border rounded border-soft-dark">
            <Row className="mb-0">
              <Col md="12">
                <p className="mb-3 pb-2 border-bottom">
                  <strong>BAHRAIN</strong>
                </p>
              </Col>
              <Col md="12">
                <Label>Min. Points require to redeem</Label>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      value=""
                      placeholder="Enter point"
                      min={0}
                    />
                    <InputGroupText className="rounded-end">
                      Points
                    </InputGroupText>
                    <FormFeedback>ddsdsd</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col md="12">
                <div className="form-check form-switch form-switch-md form-switch-secondary mb-3">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="SwitchCheck2"
                    defaultChecked
                  />
                  <Label className="form-check-label " htmlFor="SwitchCheck2">
                    Set Max. Limits per transaction
                  </Label>
                </div>
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      value=""
                      placeholder="Enter point"
                      min={0}
                    />
                    <InputGroupText className="rounded-end">
                      Points
                    </InputGroupText>
                    <FormFeedback>ddsdsd</FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <hr />
          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md">
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
            >
              Create
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default RewardRedemption;
