import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import {
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ButtonGroup,
} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

// third-party
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';

// project-imports

import {
  updatePromotionSchedule,
  createOrUpdateCoupon,
  getCoupons,
  updatePromotionTriggers,
  deleteCouponById,
} from '../../../actions/promotion';

import CouponList from './CouponList';

import AutoGenerateCoupons from './AutoGenerateCoupons';
import GenerateCouponDirect from './GenerateCouponDirect';
import CreateCouponFromCsv from './CreateCouponFromCsv';

import { QueryBuilderApp } from '../QueryBuilderApp';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';

// constant
const createCouponOptions = [
  {
    label: 'Auto Generate',
    value: 'auto_generate',
  },
  {
    label: 'Direct Input',
    value: 'direct_input',
  },
  {
    label: 'Import from CSV',
    value: 'import_data_from_csv',
  },
];

const PromotionCoupons = ({
  onCancel = () => {},
  getStores = () => {},
  promotionId,
  promotionDetail,
  updateHandler = () => {},
}) => {
  let navigate = useNavigate();
  const [isCouponRequired, setIsCouponRequired] = useState(
    promotionDetail.promotionType !== 'campaign'
  );
  const [selectedCreateOption, setSelectedCreateOption] =
    useState('auto_generate');

  const [coupons, setCoupons] = useState([]);
  const [pageOffset, setPageOffset] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isShowSummery, setIsShowSummery] = useState(true);
  const [isRefreshTable, setIsRefreshTable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [couponQuery, setCouponQuery] = useState(null);

  const CouponSchema = Yup.object().shape({
    code: Yup.string().required('Code is required'),
    startsAt: Yup.date().required('Starts At is required'),
    expiresAt: Yup.date().required('Expires At is required'),
    redeemableQuantity: Yup.number().required('Quantity is required'),
    isCouponRequired: Yup.boolean(),
  });

  const ArrayCouponSchema = Yup.array().of(CouponSchema);

  const validationSchema = Yup.object().shape({
    codes: ArrayCouponSchema,
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: coupons,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const coupons = values
        ?.map((coupon) => {
          if (coupon?.isEdited) {
            return coupon;
          }
          return null;
        })
        ?.filter((coupon) => coupon !== null);
      if (coupons?.length > 0) {
        createOrUpdateCouponFunc(coupons, pageOffset);
      } else {
        navigate(`/promotions/detail/${promotionId}/conditions-effects`);
      }
    },
  });

  const createOrUpdateCouponFunc = async (coupons, offset) => {
    setIsLoading(true);
    try {
      const variables = {
        input: {
          pageOffset: offset,
          pageSize: pageSize,
          coupons: coupons?.map((coupon) => ({
            code: coupon?.code,
            expiresAt: coupon?.expiresAt,
            redeemableQuantity: Number(coupon?.redeemableQuantity),
            startsAt: coupon?.startsAt,
            _id: coupon?._id || null,
            promotionId: promotionId,
            isActive: !!coupon?.isActive,
          })),
        },
      };

      if (coupons?.length > 0) {
        const { success, message, data } = await createOrUpdateCoupon(
          variables
        );

        // if (success && promotionId) {
        //   navigate(`/promotions/detail/${promotionId}/conditions-effects`);
        // }

        if (success) {
          setCoupons([...data?.coupons]);
          setPageOffset(data?.pageOffset);
          setTotalPages(data?.totalPages);
          setIsRefreshTable(true);
          setIsShowSummery(true);
          updateHandler();
          alertSuccess('Coupon updated successfully');
        } else {
          alertError('Failed, Please try after sometime.');
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCouponByIdFunc = async (id) => {
    const variables = {
      input: {
        _id: id,
      },
    };

    const { success } = await deleteCouponById(variables);

    await getCouponsFunc();

    if (success) {
      alertSuccess('Coupon deleted successfully');
    } else {
      alertError('Failed!, Please try again after some time');
    }
  };

  const updateCoupon = async (index, couponObj) => {
    let temp = [...coupons];
    temp[index] = await { ...couponObj };
    setCoupons([...temp]);
  };

  const getCouponsFunc = async () => {
    setIsLoading(true);
    const variables = {
      input: {
        pageOffset: pageOffset,
        pageSize: pageSize,
        promotionId: promotionId,
      },
    };

    const { data, success } = await getCoupons(variables);

    if (success) {
      setCoupons([...data?.coupons]);
      setPageOffset(data?.pageOffset);
      setTotalPages(data?.totalPages);
      setIsRefreshTable(true);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  useEffect(() => {
    if (promotionId) {
      getCouponsFunc();
    }
  }, [pageOffset]);

  useEffect(() => {
    setCouponQuery(promotionDetail?.triggerRules);
    setIsCouponRequired(promotionDetail?.promotionType !== 'campaign');
  }, [promotionDetail]);

  const saveTriggers = async () => {
    // dispatch(setIsShowRuleValidationError(true));
    if (true) {
      // const triggers = generateTriggerJsonLogic(query);
      const variables = {
        input: {
          promotionId: promotionId,
          triggerRules: couponQuery,
          promotionType: isCouponRequired ? 'coupon' : 'campaign',
        },
      };

      const { success, data, message } = await updatePromotionTriggers(
        variables
      );
      if (success) {
        alertSuccess(message);
      } else {
        alertError('Failed!, Please try again after some time');
      }
      // dispatch(setIsShowRuleValidationError(false));
    } else {
      console.log('Invalid query');
    }
  };

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <CardBody className="p-4">
      <Row className="mb-4">
        <Col xs={12}>
          <div>
            <Label>When to trigger this campaign?</Label>
          </div>
          {/* <div className="d-flex gap-3">
            <Input
              type="radio"
              className="btn-check"
              name="options-outlined"
              id="applying-manual"
            />
            <Label
              className="btn btn-outline-secondary shadow-none d-flex flex-column align-items-center gap-1"
              style={{ minWidth: 130 }}
              for="applying-manual"
            >
              <i class="ri-file-edit-line fs-32"></i>
              Applying Manual
            </Label>

            <Input
              type="radio"
              className="btn-check"
              name="options-outlined"
              id="auto-applied"
            />
            <Label
              className="btn btn-outline-secondary shadow-none d-flex flex-column align-items-center gap-1"
              style={{ minWidth: 130 }}
              for="auto-applied"
            >
              <i class="ri-coupon-2-line fs-32"></i>
              Auto Applied
            </Label>

            <Input
              type="radio"
              className="btn-check"
              name="options-outlined"
              id="event"
            />
            <Label
              className="btn btn-outline-secondary shadow-none d-flex flex-column align-items-center gap-1"
              style={{ minWidth: 130 }}
              for="event"
            >
              <i class="ri-calendar-todo-line fs-32"></i>
              By Event
            </Label>
          </div> */}
          <div
            className="btn-group"
            role="group"
            aria-label="Basic radio toggle button group"
          >
            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio1"
              autocomplete="off"
              checked={isCouponRequired}
              onChange={(event) => {
                setIsCouponRequired(event.target.checked);
              }}
            />
            <label
              className="btn btn-outline-secondary shadow-none d-flex flex-row align-items-center gap-3 fs-14 text-start"
              for="btnradio1"
            >
              <i class="ri-file-edit-line fs-24"></i>
              By Applying Manual Coupon
            </label>

            <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio2"
              autocomplete="off"
              checked={!isCouponRequired}
              onChange={(event) => {
                setIsCouponRequired(!event.target.checked);
              }}
            />
            <label
              className="btn btn-outline-secondary shadow-none d-flex flex-row align-items-center gap-3 fs-14 text-start"
              for="btnradio2"
            >
              <i class="ri-coupon-2-line fs-24"></i>
              Auto Applied at basket
            </label>

            {/* <input
              type="radio"
              className="btn-check"
              name="btnradio"
              id="btnradio3"
              autocomplete="off"
            />
            <label
              className="btn btn-outline-secondary shadow-none d-flex flex-row align-items-center gap-3 fs-14 text-start"
              for="btnradio3"
            >
              <i class="ri-calendar-todo-line fs-24"></i>
              By Event
            </label> */}
          </div>
        </Col>
      </Row>
      {/* {!promotionId?.includes('__v') && (
        <Row className="mb-2">
          <Col xs={12}>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={!isCouponRequired}
                  onChange={() => setIsCouponRequired(!isCouponRequired)}
                  size="md"
                />
                No Coupon Required
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )} */}

      {isCouponRequired ? (
        <>
          <div className="border rounded p-3 mb-5">
            {!promotionId?.includes('__v') && (
              <Row className="mb-2">
                <Col>
                  <FormGroup>
                    <Label>Please choose one option</Label>
                    <Input
                      type="select"
                      value={selectedCreateOption}
                      onChange={(e) => setSelectedCreateOption(e.target.value)}
                    >
                      {createCouponOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            )}

            {selectedCreateOption === 'auto_generate' &&
              !promotionId?.includes('__v') && (
                <AutoGenerateCoupons
                  coupons={coupons}
                  setCoupons={setCoupons}
                  setPageOffset={setPageOffset}
                  setTotalPages={setTotalPages}
                  setIsRefreshTable={setIsRefreshTable}
                  promotionId={promotionId}
                  promotionDetail={promotionDetail}
                />
              )}

            {selectedCreateOption === 'direct_input' &&
              !promotionId?.includes('__v') && (
                <GenerateCouponDirect
                  coupons={coupons}
                  setCoupons={setCoupons}
                  setPageOffset={setPageOffset}
                  setTotalPages={setTotalPages}
                  setIsRefreshTable={setIsRefreshTable}
                  promotionId={promotionId}
                  promotionDetail={promotionDetail}
                />
              )}

            {selectedCreateOption === 'import_data_from_csv' &&
              !promotionId?.includes('__v') && (
                <CreateCouponFromCsv
                  coupons={coupons}
                  setCoupons={setCoupons}
                  setIsShowSummery={setIsShowSummery}
                  setPageOffset={setPageOffset}
                  setTotalPages={setTotalPages}
                  setIsRefreshTable={setIsRefreshTable}
                  promotionId={promotionId}
                  promotionDetail={promotionDetail}
                />
              )}
          </div>
          {coupons.length > 0 && (
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Row className="mt-2">
                  <Col xs={12} lg={12} className="mb-2">
                    <CouponList
                      coupons={[...coupons]}
                      updateCoupon={updateCoupon}
                      //   isShowSummery={isShowSummary}
                      totalPages={totalPages}
                      pageOffset={pageOffset}
                      pageSize={pageSize}
                      setPageOffset={setPageOffset}
                      isRefreshTable={isRefreshTable}
                      setIsRefreshTable={setIsRefreshTable}
                      createOrUpdateCouponFunc={createOrUpdateCouponFunc}
                      deleteCouponByIdFunc={deleteCouponByIdFunc}
                    />
                  </Col>
                </Row>

                {!promotionId?.includes('__v') && (
                  <div className="d-flex gap-2 justify-content-end">
                    <Button outline color="danger" size="md" onClick={onCancel}>
                      Cancel
                    </Button>

                    <Button
                      loading={false}
                      type="submit"
                      color="primary"
                      size="md"
                      className="bg-gradient px-5"
                      disabled={isSubmitting}
                    >
                      {promotionDetail ? 'Save Draft' : 'Save & Next'}
                    </Button>
                  </div>
                )}
              </Form>
            </FormikProvider>
          )}
        </>
      ) : (
        <>
          <Row className="mb-4 mt-4">
            <Col>
              <QueryBuilderApp
                updateQuery={setCouponQuery}
                data={couponQuery}
                fieldGroups={['basket', 'user']}
              />
            </Col>
          </Row>
          {!promotionId?.includes('__v') && (
            <div className="d-flex gap-2 justify-content-end">
              <Button outline color="danger" size="md" onClick={() => {}}>
                Cancel
              </Button>

              <Button
                loading={false}
                color="primary"
                size="md"
                className="bg-gradient px-5"
                onClick={() => {
                  saveTriggers();
                }}
              >
                {promotionDetail ? 'Save Draft' : 'Save & Next'}
              </Button>
            </div>
          )}
        </>
      )}
      {isLoading ? <InnerLoader /> : null}
    </CardBody>
  );
};

PromotionCoupons.propTypes = {
  promotion: PropTypes.any,
  onCancel: PropTypes.func,
  promotionId: PropTypes.any,
  promotionDetail: PropTypes.any,
  updateHandler: PropTypes.func,
};

export default PromotionCoupons;
