import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import {
  Card,
  CardBody,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { getRecentTransactions } from '../../../actions/wallet/index';
import { formatDate } from '../../../helpers/formatDate'; // Import your date formatting functions
import { formatTime } from '../../../helpers/formatTime';

const NoRecordsMessage = () => {
  return (
    <tr>
      <td colSpan="8" className="text-center py-4">
        <h5 className="fs-13 my-1 fw-normal">No transactions found.</h5>
      </td>
    </tr>
  );
};

const RecentTransaction = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    getTransactionsFunc();
  }, []); // Include any dependencies that trigger a re-fetch (e.g., pageOffset)

  const getTransactionsFunc = async () => {
    try {
      const data = {
        country: 'ae',
        sortBy: 'createdAt',
        sortOrder: 'DESC',
      };

      // Call your API function to fetch transactions
      const res = await getRecentTransactions(data);

      if (res?.success) {
        setTransactions(res?.data?.data || []); // Ensure to handle empty data case
      } else {
        console.error(
          'Failed to fetch transactions:',
          res?.error || 'Unknown error'
        );
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">Recent 5 Transactions</h4>
          <a href="transactions">View All</a>
        </CardHeader>

        <CardBody>
          <div className="table-responsive table-card">
            <table className="table table-hover table-centered align-middle table-nowrap mb-0">
              <thead>
                <tr>
                  <th></th>
                  <th>Date</th>
                  <th>Transaction ID</th>
                  <th>Wallet ID</th>
                  <th className="text-right">Amount</th>
                </tr>
              </thead>
              <tbody>
                {transactions.length === 0 ? (
                  <NoRecordsMessage />
                ) : (
                  transactions?.map((transaction, index) => (
                    <tr key={index}>
                      <td>
                        <p className="fs-13 my-1">{index + 1}</p>
                      </td>
                      <td>
                        <p className="fs-13 my-1 fw-normal mb-0">
                          {formatDate(transaction?.createdAt)}
                        </p>
                        <span className="fs-10 text-muted">
                          {formatTime(transaction?.createdAt)}
                        </span>
                      </td>
                      <td>
                        <p className="fs-13 my-1 fw-normal mb-0">
                          {transaction?.transactionNo}
                        </p>
                        <p className="fs-10 text-muted m-0">
                          {transaction?.title}
                        </p>
                      </td>
                      <td>
                        <p className="fs-13 my-1 fw-normal">
                          {transaction?.walletId}
                        </p>
                      </td>

                      <td>
                        <p
                          className={`${
                            transaction?.mode === 'credit'
                              ? 'text-success'
                              : 'text-danger'
                          } fs-13 my-1 fw-semiBold text-right`}
                        >
                          {transaction?.currency} {transaction?.amount}
                        </p>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecentTransaction;
