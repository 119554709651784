import promoRestService from '../../services/PromoRestService';
export const createOrUpdateCombo = async (data) => {
  try {
    const result = await promoRestService?.post('combo/create', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const getCombos = async (data) => {
  try {
    const result = await promoRestService?.post('combo/list', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};
