import { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  FormFeedback,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

// third-party
import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';

// assets

import OptionValues from './OptionValues';
import {
  getConfigDetail,
  createUpdateConfigs,
} from '../../../actions/promotion/generals';
import { configTypes, validationSchema } from './constants';
import map from 'lodash.map';

function getStyles(name, country, theme) {
  return {
    fontWeight:
      country?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// ==============================|| CUSTOMER - ADD / EDIT ||============================== //

const fieldGroups = {
  basket: { label: 'Basket', fieldTypes: [] },
  user: { label: 'User', fieldTypes: [] },
  item: { label: 'Item', fieldTypes: [] },
  derived: { label: 'Derived', fieldTypes: [] },
};
const fieldDataTypes = {
  string: { label: 'String', fieldTypes: [] },
  json: { label: 'JSON', fieldTypes: ['jsonValue'] },
  integer: { label: 'Integer', fieldTypes: ['number'] },
  float: { label: 'Float', fieldTypes: ['number'] },
  double: { label: 'Double', fieldTypes: ['number'] },
  boolean: { label: 'Boolean', fieldTypes: ['radio'] },
  datetime: { label: 'Datetime', fieldTypes: ['datetime', 'date'] },
};

const fieldTypes = {
  text: { label: 'Text Config' },
  number: { label: 'Number Config' },
  textarea: { label: 'Textarea' },
  multiselect: { label: 'Multi Select' },
  select: { label: 'Dropdown' },
  // image: { label: 'Image Upload' },
  datetime: { label: 'Date & Time' },
  date: { label: 'Date' },
  radio: { label: 'Single Select (Radio)' },
  checkbox: { label: 'Multi Select (Checkbox)' },
  switch: { label: 'Switch' },
  jsonValue: { label: 'JSON values' },

  // "text" | "select" | "checkbox" | "radio" | "textarea" | "switch" | "multiselect" | null
};
const fieldGroupArray = Object.keys(fieldGroups).map((key) => ({
  label: fieldGroups[key].label,
  value: key,
}));
const fieldTypesArray = Object.keys(fieldTypes).map((key) => ({
  label: fieldTypes[key].label,
  value: key,
}));
const fieldDataTypesArray = Object.keys(fieldDataTypes).map((key) => ({
  label: fieldDataTypes[key].label,
  value: key,
  fieldTypes: fieldDataTypes[key].fieldTypes,
}));

const AddConfigs = ({
  getConfigs = () => {},
  configDetailFromProp = {},
  onCancel = () => {},
  configName = 'basket-playground',
}) => {
  const configId = configDetailFromProp?._id || 'new';
  console.log('configDetailFromProp', configDetailFromProp);
  const isCreating = false;

  const [configDetail, setConfigDetail] = useState({ ...configDetailFromProp });

  const [isOpenModal, setIsOpenModal] = useState(false);

  const fetchConfigDetail = async (configId) => {
    const result = await getConfigDetail({ configId });
    // console.log('getConfigDetail', result);
    if (result?.getConfigDetail?.optionValues) {
      result.getConfigDetail.optionValues =
        result?.getConfigDetail?.optionValues.map((option) => {
          delete option?.__typename;
          return option;
        });
    }

    setConfigDetail(result?.getConfigDetail);
  };
  useEffect(() => {
    if (configId && configId !== 'new' && isOpenModal) {
      fetchConfigDetail(configId);
    }
  }, [configDetailFromProp, configId, isOpenModal]);

  const { values = [] } = configDetail || {};

  const configFields = configTypes[configName].fields;

  const StoreSchema = Yup.object().shape(validationSchema[configName]);
  const initialValues = {};
  values.forEach((value) => {
    initialValues[value.fieldCode] = value.fieldValue;
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: StoreSchema,
    onSubmit: async (submitValues, { setSubmitting }) => {
      try {
        let success;
        let message;
        let error;
        if (configId) {
          const newValues = [];
          map(submitValues, (fieldValue, fieldCode) => {
            newValues.push({ fieldCode, fieldValue });
          });
          const inputData = {
            configName,
            configEnv: 'development',
            values: newValues,
            configLabel: submitValues?.configLabel,
          };

          const {
            id,
            success: createStoreSuccess,
            message: createMessage,
            error: gqError,
          } = await createUpdateConfigs(inputData, configId);
          // message = createMessage || error.message;
          success = createStoreSuccess;
          if (createStoreSuccess) {
            getConfigs();
            setIsOpenModal(false);
            onCancel();
          }

          error = gqError;
        }
        if (success) {
          alertSuccess(
            isCreating
              ? 'Config created successfully.'
              : 'Config updated successfully.'
          );
          getConfigs();
        } else {
          alertError('Failed, Please try after sometime.');
        }

        setSubmitting(false);
        // onCancel();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    getFieldValues,
    setFieldValue,
  } = formik;

  // console.error(errors);
  const fieldTypeState = getFieldProps('fieldType');
  const dataTypeState = getFieldProps('dataType');

  return (
    <FormikProvider value={formik}>
      <Button
        color="primary"
        size="sm"
        onClick={() => {
          setIsOpenModal(true);
        }}
      >
        {configId === 'new' ? (
          'Create new field'
        ) : (
          <i className="bx bx-edit fs-18"></i>
        )}
      </Button>
      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          {configId === 'new' ? 'Create new field' : 'Edit Config'}
        </ModalHeader>

        <ModalBody>
          <Form autoComplete="off" onSubmit={handleSubmit}>
            <Row>
              <Col xs={12}>
                <Row>
                  {configFields.map((field) => {
                    // console.log('formikformikformik', formik);
                    if (field.showWhen && !field.showWhen(formik.values)) {
                      return null;
                    }
                    if (field.type === 'text' || field.type === 'number') {
                      return (
                        <Col xs={6}>
                          <FormGroup>
                            <Label for={field.name}>{field.label}</Label>
                            <Input
                              type={field.isParagraph ? 'textarea' : 'text'}
                              rows={field.initialNumberOfLines || 1}
                              // multiple={field.isParagraph}
                              id={field.name}
                              placeholder={field.placeholder}
                              {...getFieldProps(`${field.name}`)}
                              invalid={
                                touched[field.name] && !!errors[field.name]
                              }
                            />
                            {touched[field.name] && errors[field.name] && (
                              <FormFeedback>{errors[field.name]}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      );
                    } else if (field.type === 'dropdown') {
                      return (
                        <Col xs={6}>
                          <FormGroup>
                            <Label for="field-fieldGroup">{field.label}</Label>
                            <Input
                              type="select"
                              id={field.name}
                              {...getFieldProps(`${field.name}`)}
                              invalid={
                                touched[field.name] && !!errors[field.name]
                              }
                            >
                              {field.options.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Input>
                            {touched[field.name] && errors[field.name] && (
                              <FormFeedback>{errors[field.name]}</FormFeedback>
                            )}
                          </FormGroup>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    color="light"
                    onClick={() => setIsOpenModal(false)}
                    className="w-auto"
                  >
                    Close
                  </Button>
                  <Button
                    color="primary w-auto"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {configDetail?.fieldName ? 'Save Draft' : 'Save & Next'}
                  </Button>
                </div>
              </Col>
            </Row>
            {/* <Row>
              <Col className="text-end">
                <Button type="submit" color="primary" disabled={isSubmitting}>
                  {configDetail?.fieldName ? "Save Draft" : "Save & Next"}
                </Button>
              </Col>
            </Row> */}
          </Form>
        </ModalBody>
      </Modal>
    </FormikProvider>
  );
};

export default AddConfigs;
