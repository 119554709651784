export const formatDate = (dateProp, type = 'EEE dd MMM') => {
  const date = new Date(dateProp);

  if (type === 'EEE dd MMM') {
    return date
      .toLocaleDateString('en-US', {
        weekday: 'short',
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      })
      .replace(',', '');
  }
};
