import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  InputGroup,
  InputGroupText,
  CardBody,
  Button,
  FormFeedback,
} from 'reactstrap';
import { useFormik, FormikProvider, FieldArray, Form } from 'formik';
import * as Yup from 'yup';
import { updatePromotionCostDistribution } from '../../../actions/promotion';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import ChooseTeam from '../ChooseTeam';
const PromotionCostDistribution = ({
  onCancel,
  promotionId,
  promotionDetail,
}) => {
  const validationSchema = Yup?.object({
    selectedTeams: Yup?.array()
      ?.of(
        Yup?.object({
          teamName: Yup?.string()?.required('Team name is required'),
          percentage: Yup?.number()
            ?.required('Percentage is required')
            ?.min(0, 'Percentage must be at least 0')
            ?.max(100, 'Percentage cannot be more than 100'),
        })
      )
      ?.test(
        'total-percentage',
        'The sum of all percentages must be 100',
        (selectedTeams) => {
          const totalPercentage = selectedTeams?.reduce(
            (sum, team) => sum + team?.percentage,
            0
          );
          return totalPercentage === 100;
        }
      ),
  });

  const formik = useFormik({
    initialValues: {
      selectedTeams: [],
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const variables = {
          input: {
            promotionId: promotionId,
            costDistribution: values?.selectedTeams,
          },
        };

        const { success, message } = await updatePromotionCostDistribution(
          variables
        );

        if (success) {
          alertSuccess(message);
        } else {
          alertError({ message: message });
        }

        setTimeout(() => {
          setSubmitting(false);
        }, 600);
      } catch (error) {
        console.log(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    handleChange,
    handleBlur,
    setValues,
    values,
  } = formik;

  useEffect(() => {
    if (promotionDetail) {
      setValues({
        selectedTeams: promotionDetail?.costDistribution || [],
      });
    }
  }, [promotionDetail, setValues]);

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form onSubmit={formik?.handleSubmit}>
          {values?.selectedTeams?.map((item, index) => (
            <Row key={index} className="mb-3 align-items-center">
              <Col xs="auto" className="text-right" style={{ width: 110 }}>
                <Label>{item?.teamName}</Label>
              </Col>
              <Col xs="auto">
                <FormGroup>
                  <InputGroup>
                    <Input
                      type="number"
                      name={`selectedTeams[${index}].percentage`}
                      value={item?.percentage}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Enter percentage"
                      min={0}
                      invalid={
                        touched?.selectedTeams?.[index]?.percentage &&
                        Boolean(errors?.selectedTeams?.[index]?.percentage)
                      }
                    />
                    <InputGroupText>%</InputGroupText>
                    <FormFeedback>
                      {errors?.selectedTeams?.[index]?.percentage}
                    </FormFeedback>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
          ))}

          {values?.selectedTeams?.length > 0 &&
            errors?.selectedTeams &&
            typeof errors?.selectedTeams === 'string' && (
              <div className="text-danger mb-2 fs-13">
                {errors?.selectedTeams}
              </div>
            )}

          <ChooseTeam
            teamHandler={(value) => {
              setValues({ selectedTeams: value });
            }}
            selectedTeamsProp={values?.selectedTeams}
          />

          <hr />

          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              loading={false}
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
            >
              {promotionDetail ? 'Save Draft' : 'Save & Next'}
            </Button>
          </div>
        </Form>
      </FormikProvider>
      {isSubmitting ? <InnerLoader /> : null}
    </CardBody>
  );
};

export default PromotionCostDistribution;
