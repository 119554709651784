import React, { useEffect, useState } from 'react';
import { useLocation, Link, Outlet, useParams } from 'react-router-dom';

// reactstrap
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';

import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { useNavigate } from 'react-router-dom';
import RewardTypeForm from './RewardTypeBasicSettingsForm';
import RewardTypeBasicSettingsForm from './RewardTypeBasicSettingsForm';
import RewardTierForm from './RewardTierForm';
import { getRewardDetails } from '../../../actions/rewards';
import PtcRate from './PtcRate';
import RewardRedemption from './RewardRedemption';
import RewardMedias from './RewardMedias';

const RewardTypeDetails = () => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [rewardDetails, setRewardDetails] = useState(null);

  useEffect(() => {
    if (params?.id) {
      getRewardDetailsFunc(params?.id);
    }
  }, [params?.id]);

  const getRewardDetailsFunc = async (id) => {
    try {
      const { success, data } = await getRewardDetails(id);
      if (success) {
        setRewardDetails(data);
      } else {
        setRewardDetails(null);
      }
    } catch (error) {}
  };

  const [activeTab, setActiveTab] = useState('0');

  useEffect(() => {
    let selectedTab = '0';
    switch (pathname) {
      case `/reward-types/detail/${params.id}/basic`:
        selectedTab = '0';
        break;
      case `/reward-types/detail/${params.id}/tiers`:
        selectedTab = '1';
        break;
      case `/reward-types/detail/${params.id}/ptc-rate`:
        selectedTab = '2';
        break;

      case `/reward-types/detail/${params.id}/redemption`:
        selectedTab = '3';
        break;
      case `/reward-types/detail/${params.id}/media`:
        selectedTab = '4';
        break;
      default:
        selectedTab = '0';
    }
    setActiveTab(selectedTab);
  }, [pathname]);

  useEffect(() => {
    if (params?.id !== 'new') {
    }
  }, [params?.id]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Row className="align-items-center justify-content-between ps-4 pe-4 pt-4 pb-2">
            <Col xs="auto">
              <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                {rewardDetails ? 'Reward  Details' : 'Create New'}
              </h4>
            </Col>
          </Row>
          <Nav tabs className="nav  nav-tabs-custom rounded ">
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '0' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('0')}
                tag={Link}
                to={`/reward-types/detail/${params.id}/basic`}
              >
                <i className="bx bx-user ms-2 me-2 fs-18"></i>
                Basic Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '1' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('1')}
                tag={Link}
                to={`/reward-types/detail/${params.id}/tiers`}
              >
                <i className="bx bx-calendar-event ms-2 me-2 fs-18 "></i>
                Tiers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '2' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('2')}
                tag={Link}
                to={`/reward-types/detail/${params.id}/ptc-rate`}
              >
                <i className="bx bx-badge-check ms-2 me-2 fs-18"></i>
                PTC Rate
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '3' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('3')}
                tag={Link}
                to={`/reward-types/detail/${params.id}/redemption`}
              >
                <i className="bx bx-info-circle ms-2 me-2 fs-18"></i>
                Redemption
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '4' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('4')}
                tag={Link}
                to={`/reward-types/detail/${params.id}/media`}
              >
                <i className="bx bx-dollar ms-2 me-2 fs-18"></i>
                Media
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <div className="p-4">
                {activeTab === '0' ? (
                  <RewardTypeBasicSettingsForm
                    reward={rewardDetails}
                    rewardId={params?.id}
                  />
                ) : null}
                {activeTab === '1' ? (
                  <RewardTierForm
                    reward={rewardDetails}
                    rewardId={params?.id}
                  />
                ) : null}
                {activeTab === '2' ? <PtcRate /> : null}
                {activeTab === '3' ? <RewardRedemption /> : null}
                {activeTab === '4' ? <RewardMedias /> : null}
              </div>
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  );
};

export default RewardTypeDetails;
