import axios from 'axios';
import { PROMOTION_API_SERVICE_BASE_URL } from '../../constants';

export const viewBasket = async (data) => {
  const result = await axios.post(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/calculate`,
    data
  );
  return result;
};

export const saveBasket = async (data) => {
  const result = await axios.post(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/save`,
    data
  );
  return result;
};

export const getBaskets = async (data) => {
  const result = await axios.get(
    `${PROMOTION_API_SERVICE_BASE_URL}basket/list`,
    { params: data }
  );
  return result;
};
