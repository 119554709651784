import React from 'react';
import {
  CardBody,
  Col,
  Row,
  Button,
  Input,
  FormGroup,
  Label,
  FormFeedback,
  Form,
} from 'reactstrap';
import { updatePromotionBudget } from '../../../actions/promotion';
import { useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';

const PromotionBudgeting = ({ onCancel, promotionId, promotionDetail }) => {
  const { budget } = promotionDetail;

  const validationSchema = Yup?.object()?.shape({
    noOfRedemptions: Yup?.object()?.shape({
      perDay: Yup?.number()
        ?.typeError('Must be a number')
        ?.required('Required')
        ?.min(1, 'Must be positive'),
      perWeek: Yup?.number()
        ?.typeError('Must be a number')
        ?.required('Required')
        ?.min(1, 'Must be positive'),
      perMonth: Yup?.number()
        ?.typeError('Must be a number')
        ?.required('Required')
        ?.min(1, 'Must be positive'),
      perYear: Yup?.number()
        ?.typeError('Must be a number')
        ?.required('Required')
        ?.min(1, 'Must be positive'),
      perUser: Yup?.number()
        ?.typeError('Must be a number')
        ?.required('Required')
        ?.min(1, 'Must be positive'),
      total: Yup?.number()
        ?.typeError('Must be a number')
        ?.required('Required')
        ?.min(1, 'Must be positive'),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      maxAmount: {
        perDay: budget?.maxAmount?.perDay,
        perWeek: budget?.maxAmount?.perWeek,
        perMonth: budget?.maxAmount?.perMonth,
        perYear: budget?.maxAmount?.perYear,
        perUser: budget?.maxAmount?.perUser,
        total: budget?.maxAmount?.total,
      },
      showMaxAmount: {
        perDay: budget?.maxAmount?.perDay > 0 || false,
        perWeek: budget?.maxAmount?.perWeek > 0 || false,
        perMonth: budget?.maxAmount?.perMonth > 0 || false,
        perYear: budget?.maxAmount?.perYear > 0 || false,
        perUser: budget?.maxAmount?.perUser > 0 || false,
        total: budget?.maxAmount?.total > 0 || false,
      },
      noOfRedemptions: {
        perDay: budget?.noOfRedemptions?.perDay,
        perWeek: budget?.noOfRedemptions?.perWeek,
        perMonth: budget?.noOfRedemptions?.perMonth,
        perYear: budget?.noOfRedemptions?.perYear,
        perUser: budget?.noOfRedemptions?.perUser,
        total: budget?.noOfRedemptions?.total,
      },
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const variables = {
          input: {
            promotionId: promotionId,
            budget: {
              noOfRedemptions: {
                perDay: values?.noOfRedemptions?.perDay,
                perWeek: values?.noOfRedemptions?.perWeek,
                perMonth: values?.noOfRedemptions?.perMonth,
                perYear: values?.noOfRedemptions?.perYear,
                perUser: values?.noOfRedemptions?.perUser,
                total: values?.noOfRedemptions?.total,
              },
              maxAmount: {
                perDay: values?.showMaxAmount?.perDay
                  ? values?.maxAmount?.perDay || 0
                  : 0,
                perWeek: values?.showMaxAmount?.perWeek
                  ? values?.maxAmount?.perWeek || 0
                  : 0,
                perMonth: values?.showMaxAmount?.perMonth
                  ? values?.maxAmount?.perMonth || 0
                  : 0,
                perYear: values?.showMaxAmount?.perYear
                  ? values?.maxAmount?.perYear || 0
                  : 0,
                perUser: values?.showMaxAmount?.perUser
                  ? values?.maxAmount?.perUser || 0
                  : 0,
                total: values?.showMaxAmount?.total
                  ? values?.maxAmount?.total || 0
                  : 0,
              },
            },
          },
        };

        const { success, message } = await updatePromotionBudget(variables);

        if (success) {
          alertSuccess(message);
        } else {
          alertError('Failed!, Please try again.');
        }

        setSubmitting(false);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    handleChange,
    handleBlur,
    values,
  } = formik;

  return (
    <CardBody className="p-4">
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          {['perDay', 'perWeek', 'perMonth', 'perYear', 'perUser', 'total'].map(
            (field) => (
              <Row key={field} className="mb-3">
                <Col
                  xs="auto"
                  className="d-flex justify-content-center align-items-center"
                >
                  <Label className="text-capitalize " style={{ width: 90 }}>
                    {field?.replace(/([A-Z])/g, ' $1')?.trim()}
                  </Label>
                </Col>
                <Col
                  xs="auto"
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormGroup>
                    <Label for={`noOfRedemptions.${field}`}>
                      No. of Redemptions
                    </Label>
                    <Input
                      type="number"
                      id={`noOfRedemptions.${field}`}
                      name={`noOfRedemptions.${field}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.noOfRedemptions?.[field]}
                      invalid={
                        touched?.noOfRedemptions?.[field] &&
                        Boolean(errors?.noOfRedemptions?.[field])
                      }
                      min={0}
                    />
                    <FormFeedback>
                      {touched?.noOfRedemptions?.[field] &&
                        errors?.noOfRedemptions?.[field]}
                    </FormFeedback>
                  </FormGroup>
                </Col>

                <Col
                  xs="auto"
                  className="d-flex justify-content-center align-items-center"
                >
                  <FormGroup>
                    <div class="form-check form-switch mt-4">
                      <Label for={`showMaxAmount.${field}`} className="mb-2">
                        Set Max Amount
                      </Label>
                      <Input
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`showMaxAmount.${field}`}
                        name={`showMaxAmount.${field}`}
                        checked={values?.showMaxAmount?.[field]}
                        onChange={() => {
                          setFieldValue(
                            `showMaxAmount.${field}`,
                            !values?.showMaxAmount?.[field]
                          );
                        }}
                        label={`Show Max Amount ${field
                          ?.replace(/([A-Z])/g, ' $1')
                          ?.trim()}`}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col
                  xs="auto"
                  className="d-flex justify-content-center align-items-center"
                >
                  {values?.showMaxAmount?.[field] && (
                    <FormGroup>
                      <Label for={`maxAmount.${field}`}>Max Amount</Label>
                      <Input
                        type="number"
                        id={`maxAmount.${field}`}
                        name={`maxAmount.${field}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.maxAmount?.[field]}
                        invalid={
                          touched?.maxAmount?.[field] &&
                          Boolean(errors?.maxAmount?.[field])
                        }
                        min={0}
                      />
                      <FormFeedback>
                        {touched?.maxAmount?.[field] &&
                          errors?.maxAmount?.[field]}
                      </FormFeedback>
                    </FormGroup>
                  )}
                </Col>
              </Row>
            )
          )}
          <hr />

          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md" onClick={onCancel}>
              Cancel
            </Button>

            <Button
              loading={false}
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
              disabled={isSubmitting}
            >
              {promotionDetail ? 'Save Draft' : 'Save & Next'}
            </Button>
          </div>
        </Form>
      </FormikProvider>
      {isSubmitting ? <InnerLoader /> : null}
    </CardBody>
  );
};

export default PromotionBudgeting;
