import {
  ApolloClientPromoService,
  ApolloClientPromoUploadService,
} from '../../services/ApolloClientPromoService';
import {
  CREATE_UPDATE_BASIC_SETTINGS,
  GET_PROMOTION_DETAIL,
  UPDATE_PROMOTION_SCHEDULE,
  CREATE_UPDATE_COUPON,
  AUTO_GENERATE_COUPONS,
  CHECK_AVAILABILITY_OF_COUPONS,
  GENERATE_COUPONS_FROM_CSV,
  GET_COUPONS,
  DELETE_COUPON_BY_ID,
  UPDATE_PROMOTION_TRIGGERS,
  GET_PROMOTIONS,
  UPDATE_PROMOTION_PRODUCT_ELIGIBILITY,
  PUBLISH_PROMOTION,
  GET_PROMOTION_VERSIONS,
  GET_PROMOTION_DETAIL_BY_VERSION,
  UPDATE_PROMOTION_BUDGET,
  UPDATE_PROMOTION_COST_DISTRIBUTION,
} from '../../gql/promotion';
import promoRestService from '../../services/PromoRestService';

export const createUpdateBasicSettings = async (input, promotionId) => {
  // must return all users and update
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_UPDATE_BASIC_SETTINGS,
      variables: { input, promotionId },
    });

    return data.createAndUpdateBasicDetailsPromotion;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const updatePromotionSchedule = async (input, promotionId) => {
  // must return all users and update
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_PROMOTION_SCHEDULE,
      variables: { input, promotionId },
    });

    return data.updatePromotionSchedule;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const getPromotions = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_PROMOTIONS,
      fetchPolicy: 'network-only',
      variables: variables,
      errorPolicy: 'ignore',
    });
    return {
      success: true,
      error: false,
      data: data?.getPromotions,
    };
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};

export const getPromotionDetail = async ({ promotionId } = {}) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_PROMOTION_DETAIL,
      fetchPolicy: 'no-cache',
      variables: { promotionId },
    });

    return data;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};

export const createOrUpdateCoupon = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_UPDATE_COUPON,
      variables: variables,
      fetchPolicy: 'no-cache',
    });

    return {
      success: data?.createOrUpdateCoupon?.success,
      message: data?.createOrUpdateCoupon?.message,
      data: data?.createOrUpdateCoupon?.data,
    };
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const autoGenerateCoupons = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: AUTO_GENERATE_COUPONS,
      variables: variables,
      fetchPolicy: 'no-cache',
    });
    return {
      success: data?.autoGenerateCoupons?.success,
      message: data?.autoGenerateCoupons?.message,
      data: data?.autoGenerateCoupons?.data,
    };
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const generateCouponsFromCsv = async (variables) => {
  try {
    const { data } = await ApolloClientPromoUploadService.mutate({
      mutation: GENERATE_COUPONS_FROM_CSV,
      variables: variables,
      fetchPolicy: 'no-cache',
    });
    return {
      success: data?.generateCouponsFromCsv?.success,
      message: data?.generateCouponsFromCsv?.message,
      data: data?.generateCouponsFromCsv?.data,
    };
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const checkAvailabilityOfCoupons = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CHECK_AVAILABILITY_OF_COUPONS,
      variables: variables,
      fetchPolicy: 'no-cache',
    });

    return {
      success: true,
      message: '',
      data: data?.checkAvailabilityOfCoupons,
    };
  } catch (error) {
    return {
      success: false,
      message: '',
      data: null,
    };
  }
};

export const getCoupons = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_COUPONS,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.getCoupons?.success,
      message: data?.getCoupons?.message,
      data: data?.getCoupons?.data,
    };
  } catch (error) {
    return {
      success: false,
      message: '',
      data: null,
    };
  }
};

export const deleteCouponById = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: DELETE_COUPON_BY_ID,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.deleteCouponById?.success,
      message: data?.deleteCouponById?.message,
      _id: data?.deleteCouponById?._id,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
      _id: null,
    };
  }
};

export const updatePromotionTriggers = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_PROMOTION_TRIGGERS,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.updatePromotionTriggers?.success,
      message: data?.updatePromotionTriggers?.message,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
    };
  }
};

export const updatePromotionProductEligibility = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_PROMOTION_PRODUCT_ELIGIBILITY,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.updatePromotionProductEligibility?.success,
      message: data?.updatePromotionProductEligibility?.message,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
    };
  }
};

export const publishPromotion = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: PUBLISH_PROMOTION,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.publishPromotion?.success,
      message: data?.publishPromotion?.message,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
    };
  }
};

export const getPromotionVersions = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_PROMOTION_VERSIONS,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: true,
      data: data?.getPromotionVersions,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
    };
  }
};

export const getPromotionDetailByVersion = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_PROMOTION_DETAIL_BY_VERSION,
      fetchPolicy: 'network-only',
      variables: variables,
    });

    return {
      data: data?.getPromotionDetailByVersion,
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const getItemCollections = async (data) => {
  try {
    const result = await promoRestService?.get(
      'itemCollection/collections',
      data
    );
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const createItemCollections = async (data) => {
  try {
    const result = await promoRestService?.post('itemCollection/create', data);
    return result?.data;
  } catch (error) {
    return null;
  }
};

export const downloadCollection = async (id) => {
  try {
    const response = await promoRestService?.get(
      `itemCollection/downloadCollection/${id}`,
      null,
      { responseType: 'blob' }
    );

    const url = window?.URL?.createObjectURL(new Blob([response?.data]));
    const link = document?.createElement('a');
    link.href = url;
    link?.setAttribute('download', `${id}.csv`);
    document?.body?.appendChild(link);
    link?.click();
    link?.remove();
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const updatePromotionBudget = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_PROMOTION_BUDGET,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.updatePromotionBudget?.success,
      message: data?.updatePromotionBudget?.message,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
    };
  }
};

export const updatePromotionCostDistribution = async (variables) => {
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: UPDATE_PROMOTION_COST_DISTRIBUTION,
      variables: variables,
      fetchPolicy: 'network-only',
    });
    return {
      success: data?.updatePromotionCostDistribution?.success,
      message: data?.updatePromotionCostDistribution?.message,
    };
  } catch (error) {
    return {
      success: false,
      message: 'Failed, Please try after sometime',
    };
  }
};
