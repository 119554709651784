import {
  ApolloClientPromoService,
  ApolloClientPromoUploadService,
} from '../../services/ApolloClientPromoService';
import {
  CREATE_UPDATE_BASIC_SETTINGS,
  DELETE_FIELD,
  GET_FIELD_DETAIL,
  GET_FIELD_LIST,
} from '../../gql/fields';
import { setFields, setFieldsMap } from '../../slices/promotions/reducer';
import { convertFieldToQueryBuilderFormat } from '../../helpers/convertFieldToQueryBuilderFormat';

export const createUpdateFields = async (input, fieldId) => {
  // must return all users and update
  try {
    const { data } = await ApolloClientPromoService.mutate({
      mutation: CREATE_UPDATE_BASIC_SETTINGS,
      variables: { input, fieldId },
    });

    return data.createAndUpdateBasicDetailsField;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: null,
    };
  }
};

export const getAllFields = async ({ pageSize, pageOffset } = {}, dispatch) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_FIELD_LIST,
      fetchPolicy: 'no-cache',
      variables: { input: { pageSize, pageOffset } },
    });
    const fields = [...data?.getFieldList?.fields] || [];
    if (fields?.length) {
      console.log('data.getFieldList.fields', data.getFieldList.fields);
      fields.push(
        {
          fieldGroup: 'basket',
          fieldName: 'Sum of Quantity',
          fieldCode: 'sumQty:',
        },
        {
          fieldGroup: 'basket',
          fieldName: 'Sum of Total',
          fieldCode: 'sumTotal:',
        },
        { fieldGroup: 'basket', fieldName: 'Contains', fieldCode: 'contains:' },
        {
          fieldGroup: 'basket',
          fieldName: 'Contains Item IDs',
          fieldCode: 'containsItemIds:',
        }
      );
    }
    if (dispatch) {
      const converted = convertFieldToQueryBuilderFormat(fields || []);

      dispatch(setFields(converted));
      const fieldsMap = {};
      converted.forEach((conv) => {
        fieldsMap[conv.name] = conv;
      });
      console.log('converted', fieldsMap);
      dispatch(setFieldsMap(fieldsMap));
    }
    return {
      success: true,
      error: false,
      data: fields || [],
      meta: {
        totalFields: data?.getFieldList?.totalFields || 0,
        totalPages: data?.getFieldList?.totalPages || 0,
      },
    };
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};

export const getFieldDetail = async ({ fieldId } = {}) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: GET_FIELD_DETAIL,
      fetchPolicy: 'no-cache',
      variables: { fieldId },
    });

    return data;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};
export const deleteField = async ({ fieldId } = {}) => {
  try {
    const { data } = await ApolloClientPromoService.query({
      query: DELETE_FIELD,
      fetchPolicy: 'no-cache',
      variables: { fieldId },
    });

    return data;
  } catch (error) {
    return {
      success: false,
      error: { ...error },
      data: [],
    };
  }
};
