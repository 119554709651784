import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';
import './style.scss';

import debounce from 'lodash.debounce';
import { DISCOUNT_TYPES } from '../../../constants';
import {
  getCombos,
  createOrUpdateCombo,
} from '../../../actions/promotion/combo';
import CreateComboForm from './CreateComboForm';

const Combo = ({ promotionId, effectId }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [combos, setCombos] = useState([]);
  const [products, setProducts] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCombos, setSelectedCombos] = useState();

  useEffect(() => {
    getCombosFunc();
  }, [pageOffset]);

  const getCombosFunc = async () => {
    try {
      setIsLoading(true);
      const inputData = {
        promotionId: promotionId,
        effectId: effectId,
        pageOffset: pageOffset,
        pageSize: 6,
      };

      const { data, message, success } = await getCombos(inputData);

      if (success) {
        setCombos(data?.combos);
        setTotalPages(data?.totalPages);
        if (pageOffset === 0) {
          setSelectedCombos([
            ...(data?.combos?.filter((item) => item?.effectIds?.length > 0) ||
              []),
          ]);
        }
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const selectComboHandler = async (combo, comboIndex) => {
    try {
      setIsLoading(true);
      const updatedCombo = { ...combo };
      const updatedCombos = [...combos];

      const index = combo?.effectIds?.findIndex((item) => item === effectId);

      if (index === -1) {
        updatedCombo.effectIds = [...updatedCombo?.effectIds, effectId];
      } else {
        updatedCombo.effectIds = [
          ...updatedCombo?.effectIds?.filter((item) => item !== effectId),
        ];
      }
      const inputData = [updatedCombo];
      const { success } = await createOrUpdateCombo(inputData);
      updatedCombos[comboIndex] = { ...updatedCombo };
      setCombos([...updatedCombos]);
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } catch (error) {
      console.log(error);
    }
  };

  const createComboHandler = () => {
    if (pageOffset === 0) {
      getCombosFunc();
    } else {
      setPageOffset(0);
    }
  };

  const pages = [...Array(totalPages)?.keys()];

  return (
    <>
      <div className="d-flex flex-column w-100">
        {selectedCombos?.map((combo, index) => {
          return index < 3 && combo?.effectIds?.includes(effectId) ? (
            <div class="container mb-3 row">
              <div class="col-6">
                {combo?.rule?.products?.map((product, index) => (
                  <div
                    className={`row border-bottom border-start p-2 ${
                      index === 0 ? 'border-top' : ''
                    }`}
                  >
                    <div class="col-6 border-end">{product?.sku}</div>
                    <div class="col-6">{product?.qty}</div>
                  </div>
                ))}
              </div>

              <div class="col-4 border">
                <div className="d-flex flex-column align-items-center justify-content-center h-100">
                  <div className="text-capitalize">
                    {combo?.rule?.discountType?.replace('_', ' ')}
                  </div>
                  <div>{combo?.rule?.discountValue}</div>
                </div>
              </div>
            </div>
          ) : null;
        })}
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setIsOpenModal(true);
          }}
          className="pt-1 pb-1 w-25"
        >
          See More & Choose Combo
        </Button>
      </div>

      <Modal
        isOpen={isOpenModal}
        toggle={() => {
          setIsOpenModal(!isOpenModal);
        }}
        centered={true}
        size="lg"
      >
        <ModalHeader
          className="modal-title pb-3 bg-light"
          toggle={() => {
            setIsOpenModal(!isOpenModal);
          }}
        >
          Please select the combos you wish to include.
        </ModalHeader>
        <ModalBody className="combos-modal">
          <div className="d-flex flex-row mb-3 justify-content-end gap-2">
            <div>
              <div
                onClick={() => setIsOpenModal(true)}
                className="btn btn-sm btn-secondary"
              >
                <i className="bx bx-plus fs-14 pe-2"></i>
                Upload CSV
              </div>
            </div>
            <div>
              <CreateComboForm
                effectId={effectId}
                promotionId={promotionId}
                submitHandler={createComboHandler}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-baseline">
            <div className="search-box d-inline-block w-100">
              <Input
                className="form-control search"
                placeholder="Search combos here"
                type="text"
                // value={searchKey}
                // onChange={(e) => handleSearch(e)}
              />
              <i className="bx bx-search-alt search-icon"></i>
            </div>
          </div>

          {combos?.map((combo, index) => {
            return (
              <div className="d-flex flex-row justify-content-between align-items-center w-100">
                <div class="container">
                  <div class="row pt-3">
                    <div class="col-auto border p-2 ">
                      <div className="form-check">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          id="auth-remember-check"
                          onClick={(e) => {
                            e.stopPropagation();
                            selectComboHandler(combo, index);
                          }}
                          checked={combo?.effectIds?.includes(effectId)}
                        />
                      </div>
                    </div>

                    <div class="col-4">
                      {combo?.rule?.products?.map((product, index) => (
                        <div
                          className={`row border-bottom p-0 ${
                            index === 0 ? 'border-top' : ''
                          }`}
                        >
                          <div class="col-8 border-end p-2">{product?.sku}</div>
                          <div class="col-4 p-2">{product?.qty}</div>
                        </div>
                      ))}
                    </div>

                    <div class="col-4 border">
                      <div className="d-flex gap-0 flex-column align-items-center justify-content-center h-100">
                        <div className="text-capitalize">
                          {combo?.rule?.discountType?.replace('_', ' ')}
                        </div>
                        <div>{combo?.rule?.discountValue}</div>
                      </div>
                    </div>
                    <div class="col-auto border-end border-top border-bottom">
                      <div className="d-flex gap-2 flex-column align-items-center justify-content-center h-100">
                        <CreateComboForm
                          effectId={effectId}
                          promotionId={promotionId}
                          submitHandler={createComboHandler}
                          comboProp={combo}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <div className="d-flex flex-row justify-content-between align-items-center mt-4">
            <div className="align-items-center justify-content-between row text-center text-sm-start">
              {totalPages > 1 ? (
                <div className="col-sm-auto mt-3 mt-sm-0">
                  <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
                    <li
                      className={`page-item  ${
                        pageOffset < 1 ? 'disabled' : ''
                      }`}
                    >
                      <Link
                        to="#"
                        className="page-link"
                        onClick={() => {
                          setPageOffset(pageOffset - 1);
                        }}
                      >
                        ←
                      </Link>
                    </li>

                    {pages?.map((page, index) => {
                      return (
                        <li
                          className={`page-item ${
                            pageOffset === index ? 'active' : ''
                          }`}
                        >
                          <Link
                            to="#"
                            className="page-link"
                            onClick={() => setPageOffset(index)}
                          >
                            {index + 1}
                          </Link>
                        </li>
                      );
                    })}

                    <li className="page-item">
                      <Link
                        to="#"
                        className={`page-link ${
                          pageOffset + 1 >= totalPages ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          setPageOffset(pageOffset + 1);
                        }}
                      >
                        →
                      </Link>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                setIsOpenModal(false);
                if (pageOffset === 0) {
                  getCombosFunc();
                } else {
                  setPageOffset(0);
                }
              }}
              type="button"
            >
              Save
            </Button>
          </div>
          {isLoading ? <InnerLoader /> : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default Combo;
