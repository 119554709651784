import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import TransactionDetailsTable from './TransactionDetailsTable';
import Select from 'react-select';
import WalletInfo from './WalletInfo';
import Data from './Data';
import { getWalletDetails } from '../../../actions/wallet';
import { formatDate } from '../../../helpers/formatDate';

const modeOptions = [
  { value: 'credit', label: 'Credit' },
  { value: 'debit', label: 'Debit' },
];

const statusOptions = [
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'hold', label: 'Hold' },
  { value: 'rejected', label: 'Rejected' },
];

const WalletUserDetails = () => {
  const { mobileNumber, country } = useParams();
  const [wallet, setWallet] = useState(null);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [selectedMode, setSelectedMode] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  useEffect(() => {
    const fetchWalletDetails = async () => {
      const res = await getWalletDetails({ mobileNumber, country });
      if (res?.success) {
        setWallet(res.data);
      }
      setLoading(false);
    };

    fetchWalletDetails();
  }, [mobileNumber, country]);

  const handleSearch = () => {
    // Implement the search functionality
  };

  const handleReset = () => {
    setSearch('');
    setSelectedMode(null);
    setSelectedStatus(null);
    // Implement the reset functionality
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Wallet'} titleActive="Wallet Details" />
        <Row className="g-3">
          <Col lg={4} xl={4} xxl={3}>
            <div className="sticky-side-div mb-4">
              <Card className="card-animate">
                <CardBody className="card-body">
                  <WalletInfo wallet={wallet} />
                  <div className="d-flex gap-2 flex-column mt-4">
                    <p className="fs-12 text-black-50 mb-1 text-center">
                      Wallet Balance
                    </p>
                    <h2 className="m-0 fs-30 text-center fw-semibold">
                      {wallet.currency}{' '}
                      <CountUp
                        start={0}
                        end={wallet.balance.redeemableAmount}
                        decimals={2}
                        duration={2}
                      />
                    </h2>
                    <p className="fs-11 text-muted m-0 text-center">
                      {wallet.currency}{' '}
                      <CountUp start={0} end={22.0} decimals={0} duration={2} />{' '}
                      expire on 31 DEC 2023{' '}
                      <Link to="/">
                        <i className=" bx bx-info-circle"></i>
                      </Link>
                    </p>
                    <div className="d-flex justify-content-center">
                      <Link
                        to="members/profile"
                        className="rounded-pill btn btn-primary py-1 fs-11"
                      >
                        View Profile
                      </Link>
                    </div>
                  </div>
                  <div className="mt-4 p-2 bg-light rounded-3">
                    <p className="m-0 fs-11 text-muted text-center">
                      Wallet{' '}
                      <span className="badge bg-soft-success text-success fs-12 fw-medium">
                        {wallet.status === 1 ? 'Active' : 'Inactive'}
                      </span>{' '}
                      on {formatDate(wallet.createdAt)}
                    </p>
                  </div>
                </CardBody>
              </Card>
              <Row>
                <Data wallet={wallet} />
              </Row>
              <Card className="card-animate">
                <CardBody className="card-body">
                  <h4 className="fs-14 mb-3">Additional Information</h4>
                  <p className="fs-12 text-muted mb-0 border-bottom py-2 pt-0">
                    Email ID : <span className="fw-medium">{wallet.email}</span>
                  </p>
                  <p className="fs-12 text-muted my-0 border-bottom py-2">
                    Mob No :{' '}
                    <span className="fw-medium">{wallet.mobileNumber}</span>
                  </p>
                  <p className="fs-12 text-muted mb-0 border-bottom py-2">
                    Joining date : {formatDate(wallet.createdAt)}
                    <span className="fw-medium">{wallet.joiningDate}</span>
                  </p>
                  <p className="fs-12 text-muted mb-0 border-bottom py-2">
                    Created at : {formatDate(wallet.createdAt)}
                    <span className="fw-medium">
                      {wallet.createdAtLocation}
                    </span>
                  </p>
                  <p className="fs-12 text-muted mb-0 py-2 pb-0">
                    Last Update : {formatDate(wallet.updatedAt)}
                    <span className="fw-medium">{wallet.lastUpdate}</span>
                  </p>
                </CardBody>
              </Card>
            </div>
          </Col>

          <Col lg={8} xl={8} xxl={9}>
            <Card>
              <CardHeader className="border-0">
                <Row className="g-4 align-items-center">
                  <Col sm>
                    <h5 className="card-title mb-0 flex-grow-1">
                      Transaction History
                    </h5>
                  </Col>
                  <Col sm="auto"></Col>
                </Row>
              </CardHeader>
              <CardBody className="border border border-dashed card-body">
                <Row className="g-3">
                  <Col sm={4}>
                    <div
                      className={'search-box me-2 mb-2 d-inline-block col-12'}
                    >
                      <Input
                        className="form-control search"
                        placeholder="Search Transaction ID"
                        type="text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                      <i className="bx bx-search-alt search-icon"></i>
                    </div>
                  </Col>

                  <Col>
                    <Select
                      placeholder="Select Mode"
                      value={selectedMode}
                      onChange={(option) => setSelectedMode(option)}
                      options={modeOptions}
                    />
                  </Col>

                  <Col>
                    <Select
                      placeholder="Select Status"
                      value={selectedStatus}
                      onChange={(option) => setSelectedStatus(option)}
                      options={statusOptions}
                    />
                  </Col>

                  <Col>
                    <div className="hstack gap-2">
                      <Button
                        className="btn btn-outline btn-light w-100"
                        onClick={handleReset}
                      >
                        <i className="ri-catalog-fill me-1 align-bottom"></i>
                        Reset
                      </Button>
                    </div>
                  </Col>
                  <Col>
                    <Button
                      color={`primary`}
                      className="btn-load w-100"
                      onClick={handleSearch}
                    >
                      <span className="d-flex align-items-center">
                        <span className="flex-grow-1 me-2">
                          <i className="ri-search-fill me-1 align-bottom"></i>
                          Search
                        </span>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardBody>
              <CardBody className="card-body">
                <TransactionDetailsTable
                  search={search}
                  mode={selectedMode?.value}
                  status={selectedStatus?.value}
                  mobileNumber={mobileNumber}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default WalletUserDetails;
