import * as Yup from 'yup';
import map from 'lodash.map';

export const configTypes = {
  'basket-playground': {
    label: 'Basket Playground',
    fields: [
      {
        label: 'Config Label',
        type: 'text',
        dataType: 'string',
        name: 'configLabel',
        placeholder: 'Config Label',
        required: true,
      },
      {
        label: 'API Endpoint',
        type: 'text',
        dataType: 'string',
        name: 'api-endpoint',
        placeholder: 'API Endpoint',
        required: true,
      },

      {
        label: 'API Method Type',
        type: 'dropdown',
        dataType: 'string',
        name: 'api-method-type',
        placeholder: 'API Method Type',
        required: true,
        options: [
          {
            label: 'GET',
            value: 'GET',
          },
          {
            label: 'POST',
            value: 'POST',
          },
          {
            label: 'PUT',
            value: 'PUT',
          },
          {
            label: 'DELETE',
            value: 'DELETE',
          },
        ],
      },
      {
        label: 'API Type',
        type: 'dropdown',
        dataType: 'string',
        name: 'api-type',
        placeholder: 'API Type',
        required: true,
        options: [
          {
            label: '--SELECT--',
            value: '',
          },
          {
            label: 'GraphQL',
            value: 'GraphQL',
          },
          { label: 'REST', value: 'REST' },
        ],
      },
      {
        label: 'GQL Query',
        type: 'text',
        isParagraph: true,
        showWhen: (fieldValues) => {
          return fieldValues['api-type'] === 'GraphQL';
        },
        initialNumberOfLines: 5,
        dataType: 'string',
        name: 'gql-query',
        placeholder: 'Enter GQL Query',
        required: false,
      },

      {
        label: 'GQL Variable Structure',
        type: 'text',
        isParagraph: true,
        showWhen: (fieldValues) => {
          return fieldValues['api-type'] === 'GraphQL';
        },
        initialNumberOfLines: 5,
        dataType: 'string',
        name: 'gql-variables',
        placeholder: 'Enter GQL Variables',
        required: false,
      },
      {
        label: 'Product Name Field',
        type: 'text',
        dataType: 'string',
        name: 'product-name-field',
        placeholder: 'Enter a product name field',
        required: true,
      },
    ],
  },
  brands: {
    label: 'Brands',
    fields: [
      {
        label: 'Brand Name',
        type: 'text',
        dataType: 'string',
        name: 'configLabel',
        placeholder: 'Config Label',
        required: true,
      },
      {
        label: 'Brand Description',
        type: 'text',
        dataType: 'string',
        name: 'api-endpoint',
        placeholder: 'API Endpoint',
        required: true,
      },
    ],
  },
  members: {
    label: 'Members Data Config',
    fields: [
      {
        label: 'Field Label',
        type: 'text',
        dataType: 'string',
        name: 'configLabel',
        placeholder: 'Enter Field Label',
        required: true,
      },
      {
        label: 'Field ID',
        type: 'text',
        dataType: 'string',
        name: 'fieldId',
        placeholder: 'Enter Field ID',
        required: true,
      },

      {
        label: 'Field Type',
        type: 'dropdown',
        dataType: 'string',
        name: 'fieldType',
        placeholder: 'Field Type',
        required: true,
        options: [
          {
            label: 'First Name',
            value: 'FirstName',
          },
          {
            label: 'Last Name',
            value: 'LastName',
          },
          {
            label: 'Email',
            value: 'Email',
          },
          {
            label: 'Mobile Number',
            value: 'MobileNumber',
          },
          {
            label: 'Date of Birth',
            value: 'DateOfBirth',
          },
          {
            label: 'Joined Date',
            value: 'JoinedDate',
          },
          { label: 'Nationality', value: 'Nationality' },
          { label: 'Gender', value: 'Gender' },
          { label: 'Language', value: 'Language' },
          { label: 'Country', value: 'country' },
          { label: 'City', value: 'City' },
          { label: 'Other Field', value: 'OtherField' },
        ],
      },
      {
        label: 'Is Primary Identifier',
        type: 'dropdown',
        dataType: 'boolean',
        name: 'isPrimaryIdentifier',
        placeholder: 'Is Primary Identifier',
        required: false,
        options: [
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Yes',
            value: 'yes',
          },
        ],
      },
      {
        label: 'Is Loyalty Card Unique ID',
        type: 'dropdown',
        isParagraph: true,
        showWhen: (fieldValues) => {
          return (
            fieldValues['fieldType'] === 'OtherField' &&
            fieldValues['isPrimaryIdentifier'] === 'yes'
          );
        },
        initialNumberOfLines: 5,
        dataType: 'string',
        name: 'isLoyaltyCardUniqueID',
        placeholder: 'Is Loyalty Card Unique ID',
        required: false,
        options: [
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Yes',
            value: 'yes',
          },
        ],
      },
      {
        label: 'Display In Profile',
        type: 'dropdown',
        isParagraph: true,
        showWhen: (fieldValues) => {
          return fieldValues['fieldType'] === 'OtherField';
        },
        initialNumberOfLines: 5,
        dataType: 'string',
        name: 'displayInProfile',
        placeholder: 'Display In Profile',
        required: false,
        options: [
          {
            label: 'No',
            value: 'no',
          },
          {
            label: 'Yes',
            value: 'yes',
          },
        ],
      },
      {
        label: 'Data Type',
        type: 'dropdown',
        isParagraph: true,
        showWhen: (fieldValues) => {
          return fieldValues['fieldType'] === 'OtherField';
        },
        initialNumberOfLines: 5,
        dataType: 'string',
        name: 'dataType',
        placeholder: 'Choose Data Type',
        required: false,
        options: [
          {
            label: 'String',
            value: 'String',
          },
          {
            label: 'Boolean',
            value: 'Boolean',
          },
          {
            label: 'Date',
            value: 'Date',
          },
          {
            label: 'DateTime',
            value: 'DateTime',
          },
        ],
      },
    ],
  },
};

export const configTypesList = {
  members: ['configLabel', 'fieldId', 'fieldType', 'isPrimaryIdentifier'],
  'basket-playground': ['configLabel', 'api-endpoint', 'api-method'],
  brands: ['configLabel', 'api-endpoint'],
};
const schema = {};
map(configTypes, (configType, key) => {
  schema[key] = {};

  configType.fields.forEach((field) => {
    schema[field.name] = Yup.string().required(`${field.name} is required`);
  });
});

export const validationSchema = schema;
