import React, { useEffect, useState } from 'react';
import { useLocation, Link, Outlet, useParams } from 'react-router-dom';

// reactstrap
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';

import {
  getPromotionDetail,
  publishPromotion,
  getPromotionDetailByVersion,
} from '../../actions/promotion';
import PromotionBasicSettings from './PromotionBasicSettings';
import PromotionSchedule from './PromotionSchedule';
import PromotionCoupons from './PromotionCoupons';
import PromotionTiers from './PromotionTiers';
import { alertError, alertSuccess } from '../../helpers/errorHandling';
import PromotionHistory from './PromotionHistory';
import { useNavigate } from 'react-router-dom';
import PromotionBudgeting from './PromotionBudgeting';
import PromotionCostDistribution from './PromotionCostDistribution';
import PromotionMessagesAndNotifications from './PromotionMessagesAndNotifications';

const PromotionDetail = ({ promotionIdFromPopup, isPopup = false }) => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const [promotionDetail, setPromotionDetail] = useState({});
  const [activeTab, setActiveTab] = useState('0');
  const promotionParamsId = params?.id || promotionIdFromPopup;

  useEffect(() => {
    let selectedTab = '0';
    switch (pathname) {
      case `/promotions/detail/${params.id}/schedule`:
        selectedTab = '1';
        break;
      case `/promotions/detail/${params.id}/coupons`:
        selectedTab = '2';
        break;
      case `/promotions/detail/${params.id}/conditions-effects`:
        selectedTab = '3';
        break;
      case `/promotions/detail/${params.id}/budgeting`:
        selectedTab = '4';
        break;
      case `/promotions/detail/${params.id}/cost-distribution`:
        selectedTab = '5';
        break;
      case `/promotions/detail/${params.id}/messages-and-notifications`:
        selectedTab = '6';
        break;
      case `/promotions/detail/${params.id}/addfields`:
        selectedTab = '7';
        break;
      case `/promotions/detail/${params.id}/cost`:
        selectedTab = '8';
        break;
      case `/promotions/detail/${params.id}/messages`:
        selectedTab = '9';
        break;
      default:
        selectedTab = '0';
    }
    setActiveTab(selectedTab);
  }, [pathname]);

  const fetchPromotionDetail = async (promotionId) => {
    const result = await getPromotionDetail({ promotionId });
    setPromotionDetail(result.getPromotionDetail);
  };

  useEffect(() => {
    if (promotionParamsId !== 'new') {
      if (promotionParamsId?.includes('__v')) {
        getPromotionDetailByVersionFunc(promotionParamsId);
      } else {
        fetchPromotionDetail(promotionParamsId);
      }
    }
  }, [promotionParamsId]);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const publishPromotionFunc = async () => {
    let promotionId = promotionParamsId;
    let versionId = null;

    if (promotionParamsId?.includes('__v')) {
      versionId = promotionParamsId;
      promotionId = promotionParamsId?.substring(
        0,
        promotionParamsId?.indexOf('__v')
      );
    }

    const variables = {
      input: {
        promotionId: promotionId,
        versionId: versionId,
      },
    };

    const { success, message } = await publishPromotion(variables);
    if (success) {
      navigate(`/promotions/detail/${promotionId}/basic`);
      fetchPromotionDetail(promotionId);
      alertSuccess(message);
    } else {
      alertError(message);
    }
  };

  const getPromotionDetailByVersionFunc = async (versionId) => {
    const variables = {
      input: {
        versionId: versionId,
      },
    };
    const { data, success } = await getPromotionDetailByVersion(variables);
    if (success) {
      setPromotionDetail({ ...data, _id: promotionDetail?._id });
    }
  };

  const updateHandler = () => {
    fetchPromotionDetail(promotionParamsId);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Row className="align-items-center justify-content-between ps-4 pe-4 pt-4 pb-2">
            <Col xs="auto">
              {promotionDetail?.basicSettings?.promotionName ? (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  {`Promotion Details - ${promotionDetail?.basicSettings?.promotionName}`}
                </h4>
              ) : (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  Promotion Details
                </h4>
              )}
            </Col>

            <Col xs="auto">
              {promotionDetail?.version > 0 ? (
                <PromotionHistory promotionDetail={promotionDetail} />
              ) : null}

              {promotionDetail?.status === 'updated' ||
              promotionParamsId?.includes('__v') ? (
                <Button
                  size="md"
                  color="success"
                  className="ms-2 px-4"
                  onClick={() => publishPromotionFunc()}
                >
                  {promotionParamsId?.includes('__v')
                    ? 'Restore this Version'
                    : 'Publish'}
                </Button>
              ) : null}
            </Col>
          </Row>
          <Nav tabs className="nav  nav-tabs-custom rounded ">
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '0' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('0')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/basic`}
              >
                <i className="bx bx-user ms-2 me-2 fs-18"></i>
                Basic Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '1' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('1')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/schedule`}
              >
                <i className="bx bx-calendar-event ms-2 me-2 fs-18 "></i>
                Schedule
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '2' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('2')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/coupons`}
              >
                <i className="bx bx-badge-check ms-2 me-2 fs-18"></i>
                Triggers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '3' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('3')}
                tag={Link}
                to={
                  !isPopup &&
                  `/promotions/detail/${params.id}/conditions-effects`
                }
              >
                <i className="bx bx-info-circle ms-2 me-2 fs-18"></i>
                Conditions & Effects
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '4' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('4')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/budgeting`}
              >
                <i className="bx bx-dollar ms-2 me-2 fs-18"></i>
                Budgeting
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '5' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('5')}
                tag={Link}
                to={
                  !isPopup &&
                  `/promotions/detail/${params.id}/cost-distribution`
                }
              >
                <i className="bx bx-pie-chart-alt ms-2 me-2 fs-18"></i> Cost
                Distribution
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '6' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('6')}
                tag={Link}
                to={
                  !isPopup &&
                  `/promotions/detail/${params.id}/messages-and-notifications`
                }
              >
                <i className="bx bx-envelope ms-2 me-2 fs-18"></i>
                Messages & Notifications
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '7' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('7')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/redemptions`}
              >
                <i className="bx bx-gift ms-2 me-2 fs-18"></i>
                Redemptions
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              {activeTab === '0' ? (
                <PromotionBasicSettings
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '1' ? (
                <PromotionSchedule
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '2' ? (
                <PromotionCoupons
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '3' ? (
                <PromotionTiers
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '4' ? (
                <PromotionBudgeting
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '5' ? (
                <PromotionCostDistribution
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '6' ? (
                <PromotionMessagesAndNotifications
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  );
};

export default PromotionDetail;
