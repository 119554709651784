import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
  UncontrolledTooltip,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import BreadCrumb from '../../../components/Common/BreadCrumb';
import Select from 'react-select';
import TransactionTable from '../WalletTransactions/TransactionTable';
import Flatpickr from 'react-flatpickr';
import TransactionExportBtn from './components/TransactionExportBtn';
const modeOptions = [
  { value: 'credit', label: 'Credit' },
  { value: 'debit', label: 'Debit' },
];

const statusOptions = [
  { value: 'approved', label: 'Approved' },
  { value: 'pending', label: 'Pending' },
  { value: 'hold', label: 'Hold' },
  { value: 'rejected', label: 'Rejected' },
];
const WalletTransactions = () => {
  const [searchFilter, setSearchFilter] = useState(null);
  const [selectedModeFilter, setSelectedModeFilter] = useState(null);
  const [selectedStatusFilter, setSelectedStatusFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState([]);
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(today.getDate() - 30);

  const formatRange = (selectedDates) => {
    if (selectedDates.length === 2) {
      const startDate = selectedDates[0].toISOString().split('T')[0];
      const endDate = selectedDates[1].toISOString().split('T')[0];
      setDateRangeFilter([startDate, endDate]);
      return `${startDate} to ${endDate}`;
    }
    return '';
  };

  const handleDateChange = (selectedDates) => {
    // Normalize dates to remove any time component
    const normalizedDates = selectedDates.map(
      (date) =>
        new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
    );
    setDateRange(formatRange(normalizedDates));
  };

  const handleSearch = () => {
    // Implement the search functionality
  };

  const resetSearch = () => {
    setSearchFilter(null);
    setSelectedModeFilter(null);
    setSelectedStatusFilter(null);
    setDateRange('');
    setDateRangeFilter([]);
  };
  //
  document.title = 'Transactions History';
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb titleInActive={'Admin'} titleActive="Transaction History" />
        <Card>
          {/* Filters and search */}
          <CardBody className="border border border-dashed card-body">
            <Row className="g-3">
              <Col className="d-flex flex-wrap align-items-start gap-2">
                <div
                  className="search-box d-inline-block w-100"
                  style={{ maxWidth: 360 }}
                >
                  <Input
                    className="form-control search"
                    placeholder="Search by Wallet Id / mobile no"
                    autoComplete={'off'}
                    type="text"
                    name="searchInput"
                    value={searchFilter ?? ''}
                    onChange={(event) =>
                      setSearchFilter(event?.target?.value || '')
                    }
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
                <div>
                  <Flatpickr
                    className="form-control"
                    style={{ width: 200 }}
                    options={{
                      mode: 'range',
                      dateFormat: 'Y-m-d',
                      maxDate: 'today',
                      defaultDate: [thirtyDaysAgo, today],
                      onChange: handleDateChange,
                    }}
                    value={dateRange}
                    placeholder="Start Date to End Date"
                  />
                </div>
                <Select
                  placeholder="Select Mode"
                  value={selectedModeFilter}
                  onChange={(option) => setSelectedModeFilter(option)}
                  options={modeOptions}
                />

                <Select
                  placeholder="Select Status"
                  value={selectedStatusFilter}
                  onChange={(option) => setSelectedStatusFilter(option)}
                  options={statusOptions}
                />
                <div className="d-flex gap-2">
                  <Button
                    color="light"
                    outline
                    className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center border-dark-subtle"
                    style={{ height: 38 }}
                    onClick={() => resetSearch()}
                  >
                    <i className="mdi mdi-restore fs-18 text-dark"></i>
                  </Button>

                  <Button
                    color="primary"
                    outline
                    className="shadow-none py-0 lh-1 d-flex align-items-center justify-content-center"
                    style={{ height: 38 }}
                  >
                    {isLoading ? (
                      <Spinner
                        size="sm"
                        className="flex-shrink-0"
                        role="status"
                      >
                        Loading...
                      </Spinner>
                    ) : (
                      <i className="ri-search-line fs-18"></i>
                    )}
                  </Button>
                </div>
                <div className="ms-auto">
                  <TransactionExportBtn
                    dateRange={dateRangeFilter}
                    status={selectedStatusFilter?.value}
                  />
                </div>
              </Col>
            </Row>
          </CardBody>

          <CardBody className="border">
            <Row className="g-3">
              <Col>
                <TransactionTable
                  search={searchFilter}
                  mode={selectedModeFilter?.value}
                  status={selectedStatusFilter?.value}
                  dateRangeFilter={dateRangeFilter}
                  setIsLoading={setIsLoading}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default WalletTransactions;
