import React, { useCallback, useEffect, useState } from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
  FormFeedback,
} from 'reactstrap';
const PtcRate = () => {
  return (
    <>
      <h4 class="fs-14 fw-medium mb-3 card-title">Redeemed Threshold </h4>
      <Row>
        <Col md={10} xl={7} xxl={6}>
          <table className="align-top mb-0 w-100">
            <tbody>
              <tr>
                <td>
                  <p className="mb-3">
                    <strong>UAE</strong>
                  </p>
                </td>
                <td className="p-0">
                  <FormGroup className="p-3 pe-2 bg-light border border-end-0 rounded-start border-soft-dark">
                    <InputGroup>
                      <Input
                        type="number"
                        value=""
                        placeholder="Enter point"
                        min={0}
                      />
                      <InputGroupText className="rounded-end">
                        Points
                      </InputGroupText>
                      <FormFeedback>ddsdsd</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </td>
                <td className="p-0 text-center">
                  <div
                    className="p-2 bg-light border-bottom border-top  border-soft-dark mb-3 d-flex align-items-center justify-content-center"
                    style={{ height: '71.5px' }}
                  >
                    <p className="mb-0 fs-18">=</p>
                  </div>
                </td>
                <td className="p-0">
                  <FormGroup className="p-3 ps-2 bg-light border border-start-0 rounded-end border-soft-dark">
                    <InputGroup>
                      <Input
                        type="number"
                        value=""
                        placeholder="Enter Amount"
                        min={0}
                      />
                      <InputGroupText className="rounded-end">
                        AED
                      </InputGroupText>
                      <FormFeedback>ddsdsd</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-3">
                    <strong>OMAN</strong>
                  </p>
                </td>
                <td className="p-0">
                  <FormGroup className="p-3 pe-2 bg-light border border-end-0 rounded-start border-soft-dark">
                    <InputGroup>
                      <Input
                        type="number"
                        value=""
                        placeholder="Enter point"
                        min={0}
                      />
                      <InputGroupText className="rounded-end">
                        Points
                      </InputGroupText>
                      <FormFeedback>ddsdsd</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </td>
                <td className="p-0 text-center">
                  <div
                    className="p-2 bg-light border-bottom border-top  border-soft-dark mb-3 d-flex align-items-center justify-content-center"
                    style={{ height: '71.5px' }}
                  >
                    <p className="mb-0 fs-18">=</p>
                  </div>
                </td>
                <td className="p-0">
                  <FormGroup className="p-3 ps-2 bg-light border border-start-0 rounded-end border-soft-dark">
                    <InputGroup>
                      <Input
                        type="number"
                        value=""
                        placeholder="Enter Amount"
                        min={0}
                      />
                      <InputGroupText className="rounded-end">
                        BHD
                      </InputGroupText>
                      <FormFeedback>ddsdsd</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </td>
              </tr>
              <tr>
                <td>
                  <p className="mb-3">
                    <strong>BAHRAIN</strong>
                  </p>
                </td>
                <td className="p-0">
                  <FormGroup className="p-3 pe-2 bg-light border border-end-0 rounded-start border-soft-dark">
                    <InputGroup>
                      <Input
                        type="number"
                        value=""
                        placeholder="Enter point"
                        min={0}
                      />
                      <InputGroupText className="rounded-end">
                        Points
                      </InputGroupText>
                      <FormFeedback>ddsdsd</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </td>
                <td className="p-0 text-center">
                  <div
                    className="p-2 bg-light border-bottom border-top  border-soft-dark mb-3 d-flex align-items-center justify-content-center"
                    style={{ height: '71.5px' }}
                  >
                    <p className="mb-0 fs-18">=</p>
                  </div>
                </td>
                <td className="p-0">
                  <FormGroup className="p-3 ps-2 bg-light border border-start-0 rounded-end border-soft-dark">
                    <InputGroup>
                      <Input
                        type="number"
                        value=""
                        placeholder="Enter Amount"
                        min={0}
                      />
                      <InputGroupText className="rounded-end">
                        OMR
                      </InputGroupText>
                      <FormFeedback>ddsdsd</FormFeedback>
                    </InputGroup>
                  </FormGroup>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="border rounded-1 border-soft-dark bg-light mw-100 p-3 mb-3">
            <p className="m-0 text-center">
              Please select countries in <strong>Basic Details</strong> before
              adding Redeemed Thresholds.
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md">
              Cancel
            </Button>

            <Button
              type="submit"
              color="primary"
              size="md"
              className="bg-gradient px-5"
            >
              Create
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PtcRate;
