import React, { useState, useEffect } from 'react';
import { FormGroup, Button, FormFeedback, Badge } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

const fields = ['totalSP', 'totalAP', 'totalDSP', 'totalQTY'];
const operators = ['+', '-', '*', '/', '(', ')'];

const FormulaCreationTool = ({ updateFormula, formula = '' }) => {
  const allowedValues = React.useMemo(() => {
    return [...fields, ...operators].map((tag) => ({
      label: tag,
      value: tag,
    }));
  }, []);

  const parseFormulaToOptions = (formula) => {
    if (!formula) return null;
    return formula
      ?.trim()
      ?.split(' ')
      ?.filter(Boolean)
      ?.map((tag) => ({
        label: tag,
        value: tag,
      }));
  };

  /**
   * validate
   * @param {*} formulaArray
   * @returns
   */
  const validateFormula = (formulaArray) => {
    try {
      if (!formulaArray) {
        return true;
      }
      const validOperators = operators?.concat([' ']);
      const expression = formulaArray
        ?.map((tag) => (validOperators.includes(tag?.value) ? tag.value : '1'))
        ?.join(' ');
      return isFinite(eval(expression));
    } catch {
      return false;
    }
  };

  const [selectedOptions, setSelectedOptions] = useState(
    parseFormulaToOptions(formula)
  );
  const [isValid, setIsValid] = useState(validateFormula(selectedOptions));

  const handleChange = (options) => {
    setSelectedOptions(options || []);
    const isValidFormula = validateFormula(options || []);
    setIsValid(isValidFormula);
    updateFormula((options || [])?.map((option) => option?.value).join(' '));
  };

  const resetFormula = () => {
    setSelectedOptions([]);
    setIsValid(true);
    updateFormula('');
  };

  return (
    <div className={`formula-input-container ${isValid ? '' : 'invalid'}`}>
      <FormGroup className="d-flex align-items-center">
        <div className="formula-input-box flex-grow-1">
          <CreatableSelect
            value={selectedOptions}
            onChange={handleChange}
            options={allowedValues}
            isMulti
            closeMenuOnSelect={true}
            isClearable={false}
            placeholder="Select or type to complete formula..."
          />
        </div>
        <Button
          color="danger"
          onClick={resetFormula}
          className="reset-button ms-2"
        >
          Reset
        </Button>
      </FormGroup>
      {!isValid && (
        <FormFeedback className="d-block">
          <Badge color="danger">Invalid formula.</Badge>
          <a href="#">Learn more</a>
        </FormFeedback>
      )}
    </div>
  );
};

export default FormulaCreationTool;
