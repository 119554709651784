import React, { useState, useEffect } from "react";

import FeatherIcon from "feather-icons-react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Button,
  Input,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
const WalletTable = () => {
  const WalletTable = [
    {
      id: "1",
      customerId: "23455",
      customerName: "Jhon Joy",
      customerMob: "0565562466",
      walletId: "132323232323",
      createdDate: "Tue 19 Dec",
      createdTime: "08:00 AM",
      updatedDate: "Tue 19 Dec",
      updatedTime: "08:00 AM",
      countryId: "UAE",
      balanceAmount: "AED 3900.20",
      customerStatus: "Active",
      customerStatusClass: "soft-success",
    },
    {
      id: "2",
      customerId: "23455",
      customerName: "Arun Jo",
      customerMob: "0565562466",
      walletId: "132323232323",
      createdDate: "Tue 19 Dec",
      createdTime: "08:00 AM",
      updatedDate: "Tue 19 Dec",
      updatedTime: "08:00 AM",
      countryId: "OMAN",
      balanceAmount: "AED 500.00",
      customerStatus: "Locked",
      customerStatusClass: "soft-danger",
    },
    {
      id: "3",
      customerId: "23455",
      customerName: "Anand Vijay",
      customerMob: "0565562466",
      walletId: "132323232323",
      createdDate: "Tue 19 Dec",
      createdTime: "08:00 AM",
      updatedDate: "Tue 19 Dec",
      updatedTime: "08:00 AM",
      countryId: "BAHRAIN",
      balanceAmount: "AED 500.00",
      customerStatus: "Active",
      customerStatusClass: "soft-success",
    },
    {
      id: "4",
      customerId: "23455",
      customerName: "Joy paul",
      customerMob: "0565562466",
      walletId: "132323232323",
      createdDate: "Tue 19 Dec",
      createdTime: "08:00 AM",
      updatedDate: "Tue 19 Dec",
      updatedTime: "08:00 AM",
      countryId: "QATAR",
      balanceAmount: "AED 500.00",
      customerStatus: "Active",
      customerStatusClass: "soft-success",
    },
  ];
  document.title = "Wallet List";
  return (
    <>
      <div className="table-responsive table-card">
        <table className="table table-nowrap table-centered align-middle">
          <thead className="bg-light text-muted">
            <tr>
              <th></th>
              <th>Created Date</th>
              <th>Wallet Id</th>
              <th>Customer Details</th>
              <th>Country</th>
              <th>Balance Amount</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {(WalletTable || []).map((item, key) => (
              <tr key={key}>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 my-1">{item.id}</h5>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">
                    {item.createdDate} {item.createdTime}
                  </h5>
                  <span className="fs-10 text-muted">
                    Last Updated at - {item.updatedDate}, {item.updatedTime}
                  </span>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">#{item.walletId}</h5>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <div>
                      <h5 className="fs-13 my-1">{item.customerName}</h5>
                      <span className="fs-12 text-muted">
                        Mob : {item.customerMob}
                      </span>
                    </div>
                  </div>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-normal">{item.countryId}</h5>
                </td>
                <td>
                  <h5 className="fs-13 my-1 fw-semiBold text-right">
                    {item.balanceAmount}
                  </h5>
                </td>
                <td>
                  <h5
                    className={`fs-11 my-1 fw-normal badge badge-${item?.customerStatusClass}`}
                  >
                    {item.customerStatus}
                  </h5>
                </td>
                <td>
                  <Link
                    to="/wallet/details"
                    className="rounded-pill btn btn-light py-1 fs-11"
                  >
                    View
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="align-items-center mt-4 pt-2 justify-content-between row text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing <span className="fw-semibold">5</span> of{" "}
            <span className="fw-semibold">25</span> Results
          </div>
        </div>
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            <li className="page-item disabled">
              <Link to="#" className="page-link">
                ←
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                1
              </Link>
            </li>
            <li className="page-item active">
              <Link to="#" className="page-link">
                2
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                3
              </Link>
            </li>
            <li className="page-item">
              <Link to="#" className="page-link">
                →
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WalletTable;
