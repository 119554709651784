import promoRestService from '../../services/PromoRestService';
export const getTransactions = async (data) => {
  try {
    const response = await promoRestService.post(
      'api/v1/adm/transaction/list',
      data
    );
    if (response?.code === 100)
      return {
        success: true,
        data: response?.data || null,
      };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const getRewardsMaster = async (data) => {
  try {
    const response = await promoRestService.post(
      'api/v1/adm/rewardType/list',
      data
    );

    if (response?.status === 200)
      return {
        success: true,
        data: response?.data || null,
      };
  } catch (error) {
    return {
      success: false,
      data: null,
    };
  }
};

export const createOrUpdateReward = async (data) => {
  try {
    const response = await promoRestService?.post(
      'api/v1/adm/rewardType/createOrUpdate',
      data
    );

    if (response?.status === 201) {
      return {
        success: true,
        data: response?.data || null,
        message: 'Reward created successfully',
      };
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      message: 'Failed, Please try again later',
    };
  }
};

export const getRewardDetails = async (id) => {
  try {
    const response = await promoRestService?.get(`api/v1/adm/rewardType`, {
      params: { id },
    });

    if (response?.status === 200) {
      return {
        success: true,
        data: response?.data || null,
        message: 'success',
      };
    }
  } catch (error) {
    return {
      success: false,
      data: null,
      message: 'Failed, Please try again later',
    };
  }
};
